import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import HeroChevron from "../../../components/hero/hero-chevron";
import DebitCardsOpenAccount from "../../../components/sign-up/debit-cards-open-account";
import ContactInfo from "../../../components/contact-info/contact-info";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";
import Icon from "../../../components/custom-widgets/icon";
import FeaturesSection from "../../../components/rewards-checking/features-section";
import ServiceStatesOpenAccountSection from "../../../components/service-states/service-states-open-account-section";
import StartSavingsSection from "../../../components/start-savings-section/start-savings-section";

const InterestRewardsChecking = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/interest-rewards-checking/hero-interest-checking-040324-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/interest-rewards-checking/hero-interest-checking-040324-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/interest-rewards-checking/hero-interest-checking-040324-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/interest-rewards-checking/hero-interest-checking-040324-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/interest-rewards-checking/hero-interest-checking-040324-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/interest-rewards-checking/hero-interest-checking-040324-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/interest-rewards-checking/hero-interest-checking-040324-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "interest-rewards-checking-hero",
    ...getHeroImgVariables(imgData),
    altText: "Man with shopping bags holding phone in big shopping mall.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Interest, Rewards, and More, All in One Checking Account",
            class: "text-white"
          }
        },
        {
          id: 3,
          button: {
            id: "interest-rewards-checking-hero-cta",
            class: "btn-white",
            text: "Open an Account",
            url: "/open-an-account"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Interest Checking"
    }
  ];

  const title = "Interest Checking Account - Earn More at WaFd Bank",
    description =
      "Earn more at WaFd Bank with a rewards checking account that earns interest and has many rewards and perks that help you save money. Open an account today!";

  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/checking-account/interest-rewards-checking"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-interest-checking-040324.jpg"
      }
    ]
  };

  const iconListData = [
    {
      icon: "badge-percent",
      text: "Earns Interest on All Balances"
    },
    {
      icon: "money-check-alt",
      text: "Free Box of Checks"
    },
    {
      icon: "key",
      text: "Complimentary 3x5” Safe Deposit Box (where available)"
    },
    {
      icon: "exchange-alt",
      text: "Wire Transfer Fee Reimbursement*"
    },
    {
      icon: "gas-pump",
      text: "5¢ per Gallon Gas Savings"
    },
    {
      icon: "tags",
      text: "Shopping Discounts"
    },
    {
      icon: "desktop",
      text: "Dark Web Monitoring"
    },
    {
      icon: "plane",
      text: "Travel and Leisure Discounts"
    },
    {
      icon: "mobile",
      text: "Mobile Phone Protection up to $800 per claim, $1,000 per year"
    },
    {
      icon: "shield-check",
      text: "Identity Fraud Expense Reimbursement Coverage up to $1,000,000"
    },
    {
      icon: "address-card",
      text: "Identity Theft Resolution Services"
    },
    {
      icon: "user-shield",
      text: "Identity Theft Protection with Credit File Monitoring"
    },
    {
      icon: "chart-line",
      text: "Credit Monitoring"
    },
    {
      icon: "medkit",
      text: "Health Discounts"
    },
    {
      icon: "phone-volume",
      text: "Telehealth Services"
    },
    {
      icon: "car",
      text: "24/7 Roadside Assistance"
    },
    {
      icon: "tachometer",
      text: "Credit Score Simulator"
    },
    {
      icon: "file-certificate",
      text: "Buyer's Protection and Extended Warranty"
    },
    {
      icon: "umbrella",
      text: "Accidental Death & Dismemberment Coverage"
    },
    {
      icon: "shuttle-van",
      text: "Rideshare Protection"
    }
  ];

  const iconListData1 = iconListData.slice(0, iconListData.length / 2);
  const iconListData2 = iconListData.slice(iconListData.length / 2, iconListData.length);

  const debitCardData = {
    sectionId: "interest-rewards-checking-debit-cards-section",
    titleText: "Let's Open Your Interest Checking Account Today",
    depositText: "Only $25 initial deposit to open."
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...seoData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Interest Checking</h1>
        <div className="row mb-4">
          <div className="col-md-6 mb-3 mb-md-0">
            <StaticImage
              src="../../../images/thumbnaill-interest-checking-040924.jpg"
              alt="Woman taking selfie with dog sitting in trunk of car outdoors."
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-md-6">
            <h3 className="text-success">Earn Interest and Money Saving Rewards? In a Checking Account? You Bet!</h3>
            <p>
              With our most rewarding checking account, you can enjoy earning interest plus a wide variety of rewards
              and benefits for the things in life that matter most, like identity theft protection and resolution, plus
              gas rewards and shopping discounts just about everywhere. All this for $0-12 a month, depending on your
              balance.
            </p>
            <div className="row align-items-center">
              <div className="col-sm-auto col-md-12 col-lg-auto mb-3 mb-sm-0 mb-md-3 mb-lg-0">
                <Link
                  to="/open-an-account"
                  id="interest-rewards-open-account-cta"
                  className="btn btn-primary w-100 w-sm-auto"
                >
                  Open an Account
                </Link>
              </div>
              <div className="col-sm col-md col-lg">
                <Link
                  to="/personal-banking/interest-rates"
                  id="interest-rates-link"
                  className="font-weight-bold text-decoration-none"
                >
                  See Today's Rates
                  <Icon name="arrow-right" class="ml-1" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <h2 className="text-success">Account Benefits and Rewards</h2>
        <h3>
          Enjoy access to benefits valued at $95+ every month! Monthly account fee is waived with an average balance of
          $5,000 or combined $50,000 balance in related accounts.
        </h3>
        <div className="row">
          <div className="col-md-6">
            {iconListData1.map((data, index) => (
              <div className="row mb-3 no-gutters" key={index} id={`interest-rewards-list-item-1-${index + 1}`}>
                <div className="mr-3">
                  <Icon name={data.icon} lib="far" class="text-success fa-fw" />
                </div>
                <div className="col">{data.text}</div>
              </div>
            ))}
          </div>
          <div className="col-md-6">
            {iconListData2.map((data, index) => (
              <div className="row mb-3 no-gutters" key={index} id={`interest-rewards-list-item-2-${index + 1}`}>
                <div className="mr-3">
                  <Icon name={data.icon} lib="far" class="text-success fa-fw" />
                </div>
                <div className="col">{data.text}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <DebitCardsOpenAccount {...debitCardData} />
      <FeaturesSection />
      <StartSavingsSection isGray={true} />
      <ServiceStatesOpenAccountSection containerClass="bg-white" />
      <ContactInfo />
      <section className="container py-0 text-sm text-muted">
        <p id="interest-rewards-checking-disclaimer-1">
          Benefits are available to personal checking account owner(s) and their joint account owners subject to the
          terms and conditions for the applicable Benefits. Some Benefits require authentication, registration and/or
          activation. Benefits are not available to a “signer” on the account who is not an account owner or to
          businesses, clubs, trusts, organizations and/or churches and their members, or schools and their
          employees/students. Restrictions may apply. For additional details access your Gas Savings benefit through
          your WaFd Online Banking.
        </p>
        <p id="interest-rewards-checking-disclaimer-2">
          Special Program Notes: The descriptions herein are summaries only and do not include all terms, conditions and
          exclusions of the Benefits described. Please refer to the actual Guide to Benefit and/or insurance documents
          for complete details of coverage and exclusions. Coverage is provided through the company named in the Guide
          to Benefit or on the certificate of insurance. Guide to Benefit and insurance documents can be found through
          your WaFd Online Banking or WaFd Bank mobile app. If you wish to name a beneficiary for your Accidental Death
          and Dismemberment Insurance coverage, please complete a beneficiary form. If there is no designated
          beneficiary on file, benefits will be paid according to the Master Policy. As an eligible account owner, you
          are enrolled as a member of the Econocheck Association, of which your financial institution is a sponsor. For
          more information, please call{" "}
          <a href="tel:866-210-0361" id="benefits-customer-service-tel-link">
            866-210-0361
          </a>
          . Insurance Products are not insured by the FDIC or any Federal Government Agency; not a deposit of or
          guaranteed by the bank or any bank affiliate. Available for the account holder and their spouse/domestic
          partner and up to six (6) dependent children age 2 and older. This is not insurance. Credit Score is a
          VantageScore 3.0 based on single bureau data. Third parties may use a different VantageScore or a different
          type of credit score to assess your creditworthiness. Once you have activated credit file monitoring, you may
          request your credit score. Once you have done so, you will have access to your score on a monthly basis.
          Health Discount Savings is NOT insurance. Registration/activation required. Shopping Rewards require
          registration/activation; available via mobile and web only. Travel and Leisure Discounts are available via
          mobile and web only.
        </p>
        <p id="interest-rewards-checking-disclaimer-3">
          *Wire transfer fees are reimbursed on request when your monthly balance is $5,000 or greater, or you have
          $50,000 or more in related accounts.
        </p>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default InterestRewardsChecking;
